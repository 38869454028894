var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('loading',{attrs:{"active":_vm.isLoading,"can-cancel":false,"color":'#aa945b',"is-full-page":_vm.fullPage},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('CCard',[_c('CCardHeader',[_c('b',[_vm._v("Tags")])]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CButton',{staticClass:"float-right",staticStyle:{"background-color":"#998542","color":"white"},on:{"click":_vm.createTag}},[_vm._v("Create Tag")])],1)],1),(_vm.tagsLength)?_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CDataTable',{attrs:{"items":_vm.tag.data,"fields":_vm.fields,"noItemsView":{ noResults: "No Tags Found", noItems: "No Tags Found" },"tableFilter":{ label : "Filter", placeholder:"Type to Search..." },"hover":"","sorter":""},scopedSlots:_vm._u([{key:"tag_items_count",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_c('CBadge',{staticClass:"mr-2",staticStyle:{"font-size":"16px","cursor":"pointer"},attrs:{"color":"success"},on:{"click":function($event){return _vm.editTagItem(item)}}},[_vm._v(_vm._s(item.tag_items_count))])],1)]}},{key:"editAction",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_c('font-awesome-icon',{staticClass:"mr-4;mb-4;",staticStyle:{"color":"green","cursor":"pointer"},attrs:{"icon":"edit"},on:{"click":function($event){return _vm.editTag(item)}}})],1)]}},{key:"deleteAction",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('td',[_c('font-awesome-icon',{staticClass:"mt-4;",staticStyle:{"color":"red","cursor":"pointer"},attrs:{"icon":"trash-alt"},on:{"click":function($event){return _vm.deleteTagConfirmation(item)}}})],1)]}}],null,false,951473564)})],1)],1):_vm._e()],1)],1),_c('CModal',{attrs:{"title":_vm.edit_tag_title,"centered":"","show":_vm.editTagModal},on:{"update:show":function($event){_vm.editTagModal=$event}}},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CInput',{attrs:{"label":"Name","type":"text"},model:{value:(_vm.selected_tag.name),callback:function ($$v) {_vm.$set(_vm.selected_tag, "name", $$v)},expression:"selected_tag.name"}})],1),_c('CCol',{attrs:{"sm":"12"}},[_c('CInput',{attrs:{"label":"Description","type":"text"},model:{value:(_vm.selected_tag.description),callback:function ($$v) {_vm.$set(_vm.selected_tag, "description", $$v)},expression:"selected_tag.description"}})],1)],1),_c('template',{slot:"footer"},[_c('CButton',{attrs:{"color":"secondary"},on:{"click":_vm.cancelEditTagModal}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"color":"success"},on:{"click":_vm.saveTag}},[_vm._v("Save")])],1)],2),_c('CModal',{attrs:{"title":"Confirmation","color":"success","centered":"","show":_vm.deleteConfirmationModal},on:{"update:show":function($event){_vm.deleteConfirmationModal=$event}}},[_vm._v(" Are you sure you want to delete a tag? "),_c('template',{slot:"footer"},[_c('CButton',{attrs:{"color":"secondary"},on:{"click":_vm.closeTagModal}},[_vm._v("Cancel")]),_c('CButton',{attrs:{"color":"success"},on:{"click":_vm.deleteTag}},[_vm._v("OK")])],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }