<template>
    <div>
        <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :color="'#aa945b'"
        :is-full-page="fullPage">
        </loading>
        <CCard> 
            <CCardHeader><b>Tags</b></CCardHeader>
            <CCardBody>
                <CRow >
                    <CCol sm="12">
                        <CButton style="background-color:#998542; color:white" class="float-right" @click="createTag">Create Tag</CButton>
                    </CCol>
                </CRow>
                <CRow v-if="tagsLength">
                    <CCol sm="12">
                        <CDataTable 
                            :items="tag.data"
                            :fields="fields"
                            :noItemsView='{ noResults: "No Tags Found", noItems: "No Tags Found" }'
                            :tableFilter='{ label : "Filter", placeholder:"Type to Search..." }'
                            hover
                            sorter>
                            <!--<template #tag_items="{item, index}">
                                <td>
                                    <span
                                        :key="tag_id"
                                        v-for="(data, tag_id) in item.tag_items"
                                        style="font-size: large"
                                    >
                                        <CBadge color="success" class="mr-2">{{data.name}}</CBadge>
                                    </span>
                                </td>
                            </template>-->
                            <template #tag_items_count="{item, index}">
                                <td>
                                    <CBadge color="success" class="mr-2" style="font-size:16px; cursor:pointer;" @click="editTagItem(item)">{{item.tag_items_count}}</CBadge>
                                </td>
                            </template>
                           
                            <template #editAction="{item, index}">
                                <td>
                                    <font-awesome-icon icon="edit" style="color:green; cursor: pointer;" class="mr-4;mb-4;" @click="editTag(item)"/>
                                </td>
                            </template>
                            <template #deleteAction="{item, index}">
                                <td>
                                    <font-awesome-icon icon="trash-alt" style="color:red; cursor: pointer;" class="mt-4;" @click="deleteTagConfirmation(item)"/>
                                </td>
                            </template>
                        </CDataTable>
                    </CCol>
                </CRow>
            </CCardBody>
        </CCard>
        <CModal :title="edit_tag_title" centered :show.sync="editTagModal">
            <CRow>
                <CCol sm="12">
                <CInput label="Name" type="text" v-model="selected_tag.name"></CInput>
                </CCol>
                <CCol sm="12">
                <CInput label="Description" type="text" v-model="selected_tag.description"></CInput>
                </CCol>
            </CRow>
            <template slot="footer">
                <CButton color="secondary" @click="cancelEditTagModal">Cancel</CButton>
                <CButton color="success" @click="saveTag">Save</CButton>
            </template>
        </CModal>
        <CModal
            title="Confirmation"
            color="success"
            centered
            :show.sync="deleteConfirmationModal"
            >
            Are you sure you want to delete a tag?
            <template slot="footer">
                <CButton color="secondary" @click="closeTagModal">Cancel</CButton>
                <CButton color="success" @click="deleteTag">OK</CButton>
            </template>
        </CModal>
    </div>
</template>
<script>
import { mapState } from "vuex";
import Vue from 'vue'

export default {
    middleware: "auth",
    name: "Tags",
    data(){
        return{
            edit_tag_title:"Edit Tag",
            isLoading:false,
            fullPage:false,
            editTagModal:false,
            selected_tag:{},
            deleteConfirmationModal:false,
            fields:[
                {key: 'name', label:'Name', _style:'width:50%;'},
                // {key: 'description', _style:'min-width:40px;', label:'Description'},
                {key: 'tag_items_count', label:'Tag Items'},
                // {key: 'editTagAction', _style:'min-width:20px;', label:''},
                {key: 'editAction', _style:'min-width:20px;', label:''},
                {key: 'deleteAction', _style:'min-width:20px;', label:''},
            ]
            
        }
    },
    async mounted() {
        this.isLoading = true;
        await this.$store.dispatch("auth/fetchUser");
        await this.$store.dispatch("tag/fetchTags");
        this.isLoading = false;
    },
    methods:{
        cancelEditTagModal(){
            this.editTagModal = false
        },
        async saveTag(){
            if(this.selected_tag.id){
               await this.updateTag() 
            }
            else{
                await this.createNewTag()
            }
        },
        async createNewTag(){

            this.isLoading = true;
            await this.$store
                .dispatch("tag/createTag", {
                    name: this.selected_tag.name,
                    description: this.selected_tag.description,
                    type: this.tag.type,
                })
                .then(() => {
                    this.isLoading = false;
                    this.$store.dispatch("tag/fetchTags");
                    this.editTagModal = false
                })
                .catch(error => {
                if (error.response) {
                    Vue.notify({
                        group: "notify",
                        type: "error",
                        text: error.response.data.error.message,
                        max: 5,
                        duration: 5000,
                        speed: 300
                    });
                } else {
                    console.log("Problem submitting New Post", error);
                }
                });
            this.isLoading = false;
        },
        async updateTag(){
            this.isLoading = true;
            await this.$store
                .dispatch("tag/updateSelectedTag", {
                    id: this.selected_tag.id,
                    name: this.selected_tag.name,
                    description: this.selected_tag.description,
                    type: this.selected_tag.type,
                    tag_items: this.selected_tag.tag_items
                })
                .then(() => {
                    this.isLoading = false;
                    this.editTagModal = false
                })
                .catch(error => {
                if (error.response) {
                    Vue.notify({
                    group: "notify",
                    type: "error",
                    text: error.response.data.message,
                    max: 5,
                    duration: 5000,
                    speed: 300
                    });
                } else {
                    console.log("Problem submitting New Post", error);
                }
                });
            this.isLoading = false;
            this.editTagModal = false
        },
        closeTagModal(){
            this.deleteConfirmationModal = false
        },
        editTagItem(item){
            let routeName = "/settings/tag/" + item.id
            this.$router.push(routeName)
        },
        createTag(){
            this.selected_tag = {}
            this.edit_tag_title = "Create New Tag"
            this.editTagModal = true
        },
        editTag(item){
            this.selected_tag = item
            this.edit_tag_title = "Edit Tag - (" + item.name +")"
            this.editTagModal = true

        },
        deleteTagConfirmation(item){
            this.selected_tag = item
            this.deleteConfirmationModal = true
        },
        async deleteTag(){
            if(this.selected_tag.id){
                this.isLoading = true;
                this.tag.data = [];
                await this.$store.dispatch("tag/deleteTag", this.selected_tag.id);
                await this.$store.dispatch("tag/fetchTags");
                this.deleteConfirmationModal = false
                this.isLoading = false;
            }
        }
    },
    computed: {
    ...mapState(["tag"]),
    ...mapState({
      tagsLength: state => state.tag.data.length
    })
  }

}
</script>